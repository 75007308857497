<template>
  <div class="card flex justify-center">
    <Breadcrumb :model="data">
      <template #item="{ item, props }">
        <router-link
          v-if="item.route"
          v-slot="{ href, navigate }"
          :to="item.route"
          custom
        >
          <a :href="href" v-bind="props.action" @click="navigate">
            <span :class="[item.icon, 'text-color']" />
            <span class="text-primary font-semibold">{{ item.label }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span class="text-surface-700 dark:text-surface-0">{{
            item.label
          }}</span>
        </a>
      </template>
      <template #separator>
        <i class="fa-light fa-chevrons-right cursor-pointer text-xs"></i>
      </template>
    </Breadcrumb>
  </div>
</template>

<script>
import Breadcrumb from "primevue/breadcrumb";

export default {
  name: "BaseBreadcrumb",

  components: {
    Breadcrumb,
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  setup() {
    return {};
  },
};
</script>