import { ref, inject, computed } from "vue";

export default function useQueueOptions() {
  const t = inject("t");
  const activeOptions = [
    { name: "Active", value: 1 },
    { name: "Inactive", value: 0 },
  ];

  const timeoutOptions = [
    { name: "0", value: 30 },
    { name: "60", value: 60 },
    { name: "90", value: 90 },
    { name: "120", value: 120 },
    { name: "150", value: 150 },
    { name: "180", value: 180 },
    { name: "210", value: 210 },
    { name: "240", value: 240 },
    { name: "270", value: 270 },
    { name: "300", value: 300 },
    { name: "330", value: 330 },
    { name: "360", value: 360 },
    { name: "390", value: 390 },
    { name: "420", value: 420 },
    { name: "450", value: 450 },
    { name: "480", value: 480 },
    { name: "510", value: 510 },
    { name: "540", value: 540 },
    { name: "570", value: 570 },
    { name: "600", value: 600 },
  ];

  const timeoutSelect = computed(() => timeoutOptions.map((item) => item.name));

  const wrapuptimeOptions = [
    { name: "0", value: 0 },
    { name: "5", value: 5 },
    { name: "10", value: 10 },
    { name: "15", value: 15 },
    { name: "20", value: 20 },
    { name: "25", value: 25 },
    { name: "30", value: 30 },
    { name: "35", value: 35 },
    { name: "40", value: 40 },
    { name: "45", value: 45 },
    { name: "50", value: 50 },
    { name: "55", value: 55 },
    { name: "60", value: 60 },
    { name: "65", value: 65 },
    { name: "70", value: 70 },
    { name: "75", value: 75 },
    { name: "80", value: 80 },
    { name: "85", value: 85 },
    { name: "90", value: 90 },
    { name: "95", value: 95 },
    { name: "100", value: 100 },
    { name: "105", value: 105 },
    { name: "110", value: 110 },
    { name: "115", value: 115 },
    { name: "120", value: 120 },
  ];

  const wrapOptions = computed(() => {
    return wrapuptimeOptions.map((item) => item.name);
  });

  const weightOptions = [
    { name: "1", value: 1 },
    { name: "2", value: 2 },
    { name: "3", value: 3 },
    { name: "4", value: 4 },
    { name: "5", value: 5 },
    { name: "6", value: 6 },
    { name: "7", value: 7 },
    { name: "8", value: 8 },
    { name: "9", value: 9 },
    { name: "10", value: 10 },
  ];

  const wieghtOptionsV2 = computed(() => {
    return weightOptions.map((item) => item.name);
  });

  const agentPeriodOptions = [
    { name: "0", value: 0 },
    { name: "5", value: 5 },
    { name: "10", value: 10 },
    { name: "15", value: 15 },
    { name: "20", value: 20 },
    { name: "25", value: 25 },
    { name: "30", value: 30 },
    { name: "35", value: 35 },
    { name: "40", value: 40 },
    { name: "45", value: 45 },
    { name: "50", value: 50 },
    { name: "55", value: 55 },
    { name: "60", value: 60 },
  ];

  const periodOptions = computed(() => {
    return agentPeriodOptions.map((item) => item.name);
  });

  const strategyOptions = [
    { name: t("xpbx.options.ringall"), value: "ringall" },
    { name: t("xpbx.options.random"), value: "random" },
    { name: t("xpbx.options.ordered"), value: "ordered" },
  ];

  const muscicOnHoldOptions = [
    { name: t("xpbx.options.default"), value: "default" },
  ];

  const fileTypeOptions = [
    { name: t("xpbx.options.ivr"), value: "ivr" },
    { name: t("xpbx.options.moh"), value: "moh" },
  ];

  const fileTypeQueueOptions = [
    {
      value: "playmoh",
      name: t("xpbx.settings.queue-actions.options.playmoh"),
    },
    {
      value: "streamfile",
      name: t("xpbx.settings.queue-actions.options.streamfile"),
    },
  ];

  const commandOptions = [
    {
      value: "amod",
      name: t("xpbx.settings.options.amod"),
    },
    {
      value: "bmod",
      name: t("xpbx.settings.options.bmod"),
    },
    {
      value: "dmod",
      name: t("xpbx.settings.options.dmod"),
    },
  ];

  const actionOptions = [
    { name: "1", value: 1 },
    { name: "2", value: 2 },
  ];

  const channelOptions = [1, 5, 10, 50, 100, 200, 300, 400, 500];

  const priorityOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const pointOptions = [
    '0', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 11, 12, 13, 14, 15, 16, 17, 18, 19,
    20,
  ];

  const codecOptions = [
    { name: t(`xpbx.settings.options.alaw`), value: "alaw" },
    { name: t(`xpbx.settings.options.ulaw`), value: "ulaw" },
    { name: t(`xpbx.settings.options.g729`), value: "g729" },
    { name: t(`xpbx.settings.options.vp8`), value: "vp8" },
  ];

  const getOptionValue = (modelValue) => {
    if (!modelValue) return null;

    const selectText = t("xpbx.settings.dropdown.select_option");

    if (typeof modelValue === "object") {
      if (modelValue.name === selectText) return null;
      return modelValue.value;
    }

    if (modelValue === selectText) return null;

    return modelValue;
  };

  return {
    actionOptions,
    activeOptions,
    weightOptions,
    wieghtOptionsV2,
    timeoutSelect,
    timeoutOptions,
    strategyOptions,
    fileTypeOptions,
    fileTypeQueueOptions,
    muscicOnHoldOptions,
    wrapOptions,
    wrapuptimeOptions,
    periodOptions,
    agentPeriodOptions,
    channelOptions,
    priorityOptions,
    codecOptions,
    commandOptions,
    pointOptions,
    // Functions
    getOptionValue,
  };
}
