import { ref, inject } from "vue";
import { useStore } from "vuex";
import api from "@/services/userApi";

export default function useCarriers() {
  const t = inject("t");
  const store = useStore();
  const carrier = ref({
    carrier_name: "",
    timezone: null,
    is_active: true,
  });
  const loading = ref(true);
  const trunks = ref([]);
  const testRule = ref(null);
  const prefixes = ref([]);
  const rules = ref([]);
  const rates = ref([]);
  const filteredRates = ref([]);
  const carriers = ref([]);
  const freeCarriers = ref([]);
  const freeAgentCarriers = ref([]);
  const notify = inject("notify");
  const n = (label, type = "danger") => {
    notify({
      message: t(`${label}`),
      type,
    });
  };

  //   FIND CARRIERS
  const findCarriers = async () => {
    try {
      const response = await api.get("/admin/xpbx/settings/carrier");

      if (response?.data) carriers.value = response.data;
    } catch (error) {
    } finally {
      loading.value = false;
    }
  };

  //   FIND CARRIER TRUNKS
  const findCarrierTrunks = async (carrierId) => {
    try {
      const response = await api.get(
        `/admin/xpbx/settings/carrier/${carrierId}/trunk`
      );

      if (response?.data) trunks.value = response.data;
    } catch (error) {}
  };

  //   FIND CARRIER
  const findCarrier = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/settings/carrier/${id}`);

      if (response?.data) {
        const form = {
          ...response.data,
          is_active: response.data.is_active === 1 ? true : false,
          // timezone: response.data?.timezone
          //   ? {
          //       value: response.data.timezone,
          //       name: response.data.timezone,
          //     }
          //   : null,
        };
        carrier.value = form;
      }
    } catch (error) {}
  };

  //   FIND FREE CARRIER
  const findFreeCarrier = async () => {
    try {
      const response = await api.get(`/admin/xpbx/free/settings/carrier`);

      if (response?.data?.length) {
        freeCarriers.value = response.data;
      }
    } catch (error) {}
  };

  //   FIND AGENT FREE CARRIER
  const findAgentFreeCarriers = async (id) => {
    try {
      const response = await api.get(`/admin/xpbx/free/settings/carrier/${id}`);

      if (response?.data?.length) {
        freeAgentCarriers.value = response.data;
      }
    } catch (error) {}
  };

  //   CREATE CARRIER
  const createCarrier = async (data) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(`/admin/xpbx/settings/carrier`, data);

      if (response?.data?.id) {
        carriers.value.push(response.data);
        n("xpbx.settings.carriers.notifications.create_carrier", "success");
      } else {
        n("xpbx.settings.carriers.notifications.error_create_carrier");
      }
    } catch (error) {
      n("xpbx.settings.carriers.notifications.error_create_carrier");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   UPDATE CARRIER
  const updateCarrier = async (data, id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/carrier/${id}`,
        data
      );

      if (response?.data?.id) {
        const index = carriers.value.findIndex((i) => i.id === id);
        carriers.value[index] = response.data;
        n("xpbx.settings.carriers.notifications.edit_carrier", "success");
      } else {
        n("xpbx.settings.carriers.notifications.error_edit_carrier");
      }
    } catch (error) {
      n("xpbx.settings.carriers.notifications.error_edit_carrier");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //   DELETE CARRIER
  const deleteCarrier = async (id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(`/admin/xpbx/settings/carrier/${id}`);

      if (response.status === 200) {
        n("xpbx.settings.carriers.notifications.delete_carrier", "success");

        carriers.value = carriers.value.filter((i) => i.id !== id);
      } else {
        n("xpbx.settings.carriers.notifications.error_delete_carrier");
      }
      return response;
    } catch (error) {
      n("xpbx.settings.carriers.notifications.error_delete_carrier");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  //  CREATE CARRIER TRUNKS
  const createCarrierTrunk = async (data, carrierId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(
        `/admin/xpbx/settings/carrier/${carrierId}/trunk`,
        data
      );

      if (response?.data?.id) {
        trunks.value.push(response.data);
        n("xpbx.settings.carriers.notifications.create_trunk", "success");
      } else {
        n("xpbx.settings.carriers.notifications.error_create_trunk");
      }
    } catch (error) {
      n("xpbx.settings.carriers.notifications.error_create_trunk");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  // UPDATE CARRIER TRUNK
  const updateCarrierTrunk = async (data, carrierId, trunkId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/carrier/${carrierId}/trunk/${trunkId}`,
        data
      );

      if (response?.data?.id) {
        const index = trunks.value.findIndex((i) => i.id === trunkId);
        trunks.value[index] = response.data;
        n("xpbx.settings.carriers.notifications.edit_trunk", "success");
      } else {
        n("xpbx.settings.carriers.notifications.error_edit_trunk");
      }
    } catch (error) {
      n("xpbx.settings.carriers.notifications.error_edit_trunk");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  // DELETE CARRIER TRUNK
  const deleteCarrierTrunk = async (carrierId, trunkId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(
        `/admin/xpbx/settings/carrier/${carrierId}/trunk/${trunkId}`
      );

      if (response.status === 200) {
        n("xpbx.settings.carriers.notifications.delete_trunk", "success");

        trunks.value = trunks.value.filter((i) => i.id !== trunkId);
      } else {
        n("xpbx.settings.carriers.notifications.error_delete_trunk");
      }
      return response;
    } catch (error) {
      n("xpbx.settings.carriers.notifications.error_delete_trunk");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  // FIND CARRIER RULES
  const findCarrierTrunkRules = async (carrierId) => {
    try {
      const ID = "56f4883f-9aa3-2ea7-4d0e-e27181303883";
      const response = await api.get(
        `/admin/xpbx/settings/trunk/${carrierId}/rules`
      );

      if (response?.data) rules.value = response.data;
    } catch (error) {}
  };

  // CREATE CARRIER RULE
  const createCarrierTrunkRule = async (data, trunkId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(
        `/admin/xpbx/settings/trunk/${trunkId}/rules`,
        data
      );

      if (response?.data?.id) {
        const form = {
          ...response.data,
          timeslot_id: response.data?.timezone_id,
        };

        rules.value.push(form);
        n("xpbx.settings.trunk_rules.notifications.create_rule", "success");
      } else {
        n("xpbx.settings.trunk_rules.notifications.error_create_rule");
      }
    } catch (error) {
      n("xpbx.settings.trunk_rules.notifications.error_create_rule");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  // UPDATE CARRIER RULE
  const updateCarrierTrunkRule = async (data, trunkId, ruleId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/trunk/${trunkId}/rules/${ruleId}`,
        data
      );

      if (response?.data?.id) {
        const index = rules.value.findIndex((i) => i.id === ruleId);
        rules.value[index] = response.data;
        n("xpbx.settings.trunk_rules.notifications.edit_rule", "success");
      } else {
        n("xpbx.settings.trunk_rules.notifications.error_edit_rule");
      }
    } catch (error) {
      n("xpbx.settings.trunk_rules.notifications.error_edit_rule");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  // DELETE CARRIER RULE
  const deleteCarrierTrunkRule = async (trunkId, ruleId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(
        `/admin/xpbx/settings/trunk/${trunkId}/rules/${ruleId}`
      );

      if (response.status === 200) {
        n("xpbx.settings.trunk_rules.notifications.delete_rule", "success");

        rules.value = rules.value.filter((i) => i.id !== ruleId);
      } else {
        n("xpbx.settings.trunk_rules.notifications.error_delete_rule");
      }
      return response;
    } catch (error) {
      n("xpbx.settings.trunk_rules.notifications.error_delete_rule");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  // FIND CARRIER RATES
  const findCarrierRates = async (carrierId) => {
    try {
      const response = await api.get(
        `/admin/xpbx/settings/carrier/${carrierId}/rate`
      );

      if (response?.data) {
        rates.value = response.data;
        filteredRates.value = response.data;
      }
    } catch (error) {}
  };

  // FILTER CARRIER RATES
  const filterRates = (array, isReset = false) => {
    // filteredRates.value = [];
    filteredRates.value = isReset ? [...rates.value] : array;
  };

  // CREATE CARRIER RATE
  const createCarrierRate = async (data, carrierId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(
        `/admin/xpbx/settings/carrier/${carrierId}/rate`,
        data
      );

      if (response?.data?.id) {
        rates.value = [response.data, ...rates.value];
        n("xpbx.settings.carriers.notifications.create_rate", "success");
      } else {
        n("xpbx.settings.carriers.notifications.error_create_rate");
      }
    } catch (error) {
      n("xpbx.settings.carriers.notifications.error_create_rate");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  // UPDATE CARRIER RATE
  const updateCarrierRate = async (data, carrierId, rateId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.put(
        `/admin/xpbx/settings/carrier/${carrierId}/rate/${rateId}`,
        data
      );

      if (response?.data) {
        const index = rates.value.findIndex((i) => i.id === rateId);
        rates.value[index] = response.data;
        n("xpbx.settings.carriers.notifications.edit_rate", "success");
      } else {
        n("xpbx.settings.carriers.notifications.error_edit_rate");
      }
    } catch (error) {
      n("xpbx.settings.carriers.notifications.error_edit_rate");
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  // DELETE CARRIER RATE
  const deleteCarrierRate = async (carrierId, rateId) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.delete(
        `/admin/xpbx/settings/carrier/${carrierId}/rate/${rateId}`
      );

      if (response.status === 200) {
        n("xpbx.settings.carriers.notifications.delete_rate", "success");

        rates.value = rates.value.filter((i) => i.id !== rateId);
      } else {
        n("xpbx.settings.carriers.notifications.error_delete_rate");
      }
      return response;
    } catch (error) {
      n("xpbx.settings.carriers.notifications.error_delete_rate");
      return error;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  // GET PREFIXES
  const findPrefixes = async () => {
    const filters = {
      filter: [{ field: "prefix", match: "bw", value: ["359"] }],
      sort: [{ field: "prefix", order: "asc" }],
    };
    try {
      const response = await api.post(
        `/admin/xpbx/settings/routing/prefixes/search `,
        filters
      );

      if (response?.data) prefixes.value = response.data;
    } catch (error) {}
  };

  // TEST RULE
  const testRuleRequest = async (data, id) => {
    try {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", true);

      const response = await api.post(
        `/admin/xpbx/settings/trunk/${id}/rules/test`,
        data
      );

      if (response?.data) {
        testRule.value = response.data;
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    } finally {
      store.commit("xadmin/SET_XADMIN_GLOBAL_LOADER", false);
    }
  };

  return {
    trunks,
    rates,
    testValue: testRule,
    filteredRates,
    prefixes,
    carrier,
    loading,
    carriers,
    freeCarriers,
    freeAgentCarriers,
    findCarrier,
    testRuleRequest,
    findCarrierTrunks,
    findCarriers,
    updateCarrier,
    createCarrier,
    deleteCarrier,
    findFreeCarrier,
    createCarrierTrunk,
    updateCarrierTrunk,
    deleteCarrierTrunk,
    findAgentFreeCarriers,
    findCarrierRates,
    // Carrier Rules
    rules,
    findCarrierTrunkRules,
    createCarrierTrunkRule,
    updateCarrierTrunkRule,
    deleteCarrierTrunkRule,
    // Rates
    filterRates,
    createCarrierRate,
    updateCarrierRate,
    deleteCarrierRate,
    // Prefixes
    findPrefixes,
  };
}
