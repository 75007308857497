<template>
  <section class="w-full">
    <div class="mt-3">
      <PageHeader
        :showButton="false"
        :heading="$t('xpbx.settings.carrier_detail.heading')"
      />
    </div>
    <div class="flex items-center justify-start">
      <BaseBreadcrumb :data="breadcrumbData" />
    </div>
    <div
      class="card relative table-wrapper"
      :class="{ 'table-none': !rules?.length }"
    >
      <DataTable
        ref="dt"
        scrollable
        :value="rules"
        :scrollHeight="`${scrollHeight}px`"
        removableSort
        :paginator="true"
        paginatorPosition="top"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20]"
        dataKey="id"
        selectionMode="single"
        v-model:selection="selectedRecords"
      >
        <template #empty>
          {{ $t("xpbx.settings.queue-vnumbers.fields.empty_table") }}</template
        >
        <template #header>
          <div
            class="flex flex-wrap gap-2 align-items-center justify-content-between w-full"
          >
            <div>
              <Button
                severity="success"
                class="mr-2 add-record-button"
                @click="openNew"
                :label="$t('xpbx.button.new')"
              />
              <Button
                severity="success"
                class="mr-2 add-record-button"
                @click="openTest"
                :label="$t('xpbx.button.test')"
              />
            </div>
            <div class="flex gap-2 items-center ml-auto"></div>
          </div>
        </template>

        <Column
          sortable
          style="width: 10%"
          field="direction"
          :header="$t('xpbx.settings.trunk_rules.fields.direction')"
        >
          <template #body="{ data }">
            <div>
              {{
                $t(
                  `xpbx.settings.trunk_rules.fields.direction.${data.direction}`
                )
              }}
            </div>
            <TableActions
              :data="data"
              :id="data.id"
              :isActive="data.is_active"
              :showIsActive="true"
              @edit="editHandle"
              @setActive="activeHandle"
              @delete="deleteSelectedRecord"
            />
          </template>
        </Column>

        <Column
          sortable
          style="width: 5%"
          field="calling_prefix"
          :header="$t('xpbx.settings.trunk_rules.fields.calling_prefix')"
        ></Column>

        <Column
          sortable
          style="width: 5%"
          field="called_prefix"
          :header="$t('xpbx.settings.trunk_rules.fields.called_prefix')"
        ></Column>

        <Column
          sortable
          style="width: 10%"
          field="timeslot_id"
          :header="$t('xpbx.settings.trunk_rules.fields.timeslot')"
        >
          <template #body="{ data }">
            <div>
              {{
                timezonesData.find(
                  (timezone) => timezone.value === data.timeslot_id
                )?.name
              }}
            </div>
          </template>
        </Column>

        <Column
          sortable
          style="width: 10%"
          field="command"
          :header="$t('xpbx.settings.trunk_rules.fields.command')"
        >
          <template #body="{ data }">
            <div>
              {{ getCommandName(data.command) }}
            </div>
          </template>
        </Column>

        <Column
          sortable
          style="width: 10%"
          field="command_arg"
          :header="$t('xpbx.settings.trunk_rules.fields.command_arg')"
        >
        </Column>

        <Column
          sortable
          style="width: 7%"
          field="point_start"
          :header="$t('xpbx.settings.trunk_rules.fields.point_start')"
        ></Column>

        <Column
          sortable
          style="width: 7%"
          field="point_end"
          :header="$t('xpbx.settings.trunk_rules.fields.point_end')"
        ></Column>

        <Column
          sortable
          style="width: 5%"
          field="priority"
          :header="$t('xpbx.settings.trunk_rules.fields.priority')"
        ></Column>
      </DataTable>

      <p class="text-center my-2" v-if="!rules?.length">
        {{ $t("xpbx.settings.queue-actions.fields.empty_table") }}
      </p>
    </div>

    <!-- Delete dialog -->
    <Dialog
      v-model:visible="deleteDialog"
      :style="{ width: '450px' }"
      header="Confirm"
      :modal="true"
      class="p-fluid relative"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="selectedRecord?.command">
          {{
            $t("xpbx.settings.trunk_rules.notification.confirm_delete", {
              delete: `rule ${selectedRecord?.command}`,
            })
          }}</span
        >
      </div>
      <template #footer>
        <Button
          text
          @click="deleteDialog = false"
          :label="$t('xpbx.settings.queue-vnumbers.buttons.no')"
        />
        <Button
          text
          @click="deleteSelectedRecords"
          :label="$t('xpbx.settings.queue-vnumbers.buttons.yes')"
        />
      </template>
    </Dialog>

    <!-- Create template -->
    <Dialog
      v-model:visible="createDialog"
      :style="{ width: '700px' }"
      :header="
        isEdit
          ? $t('xpbx.settings.trunk_rules.dialogs.edit_trunk_rules')
          : $t('xpbx.settings.trunk_rules.dialogs.create_trunk_rules')
      "
      :modal="true"
      :dismissableMask="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!-- Direction -->
      <FormDropdown
        optionLabel="name"
        :options="trunktypeOptions"
        :error="errors?.direction?.[0]"
        v-model:modelValue="record.direction"
        :label="$t('xpbx.settings.trunk_rules.labels.direction')"
        :hint="$t('xpbx.settings.trunk_rules.hints.direction')"
      />

      <!--Calling Prefix -->
      <FormInput
        id="calling_prefix"
        :error="errors?.calling_prefix?.[0]"
        v-model:modelValue="record.calling_prefix"
        :label="$t('xpbx.settings.trunk_rules.labels.calling_prefix')"
        :hint="$t('xpbx.settings.trunk_rules.hints.calling_prefix')"
      />

      <!--Called Prefix -->
      <FormInput
        id="called_prefix"
        :error="errors?.called_prefix?.[0]"
        v-model:modelValue="record.called_prefix"
        :label="$t('xpbx.settings.trunk_rules.labels.called_prefix')"
        :hint="$t('xpbx.settings.trunk_rules.hints.called_prefix')"
      />

      <!-- Timezone -->
      <FormDropdown
        optionLabel="name"
        :options="timezonesData"
        :error="errors?.timeslot_id?.[0]"
        v-model:modelValue="record.timeslot_id"
        :placeholder="$t('xpbx.settings.trunk_rules.placeholders.timezone')"
        :label="$t('xpbx.settings.trunk_rules.labels.timeslot')"
        :hint="$t('xpbx.settings.trunk_rules.hints.timeslot')"
      />

      <!-- Command -->
      <FormDropdown
        optionLabel="name"
        :options="commandOptions"
        :error="errors?.command?.[0]"
        v-model:modelValue="record.command"
        :label="$t('xpbx.settings.trunk_rules.labels.command')"
        :hint="$t('xpbx.settings.trunk_rules.hints.command')"
      />

      <!--Command arg -->
      <FormInput
        id="command_arg"
        :error="errors?.command_arg?.[0]"
        v-model:modelValue="record.command_arg"
        :label="$t('xpbx.settings.trunk_rules.labels.command_arg')"
        :hint="$t('xpbx.settings.trunk_rules.hints.command_arg')"
      />

      <!-- Point Start -->
      <FormDropdown
        :options="pointOptions"
        :error="errors?.point_start?.[0]"
        v-model:modelValue="record.point_start"
        :label="$t('xpbx.settings.trunk_rules.labels.point_start')"
        :hint="$t('xpbx.settings.trunk_rules.hints.point_start')"
      />

      <!-- Point end -->
      <FormDropdown
        :options="pointOptions"
        :error="errors?.point_end?.[0]"
        v-model:modelValue="record.point_end"
        :label="$t('xpbx.settings.trunk_rules.labels.point_end')"
        :hint="$t('xpbx.settings.trunk_rules.hints.point_end')"
      />

      <!-- Priority -->
      <FormDropdown
        :options="priorityOptions"
        :error="errors?.priority?.[0]"
        v-model:modelValue="record.priority"
        :label="$t('xpbx.settings.trunk_rules.labels.priority')"
        :hint="$t('xpbx.settings.trunk_rules.hints.priority')"
      />

      <!-- Is active -->
      <InputSwitch
        v-model:modelValue="record.is_active"
        :label="$t('xpbx.settings.trunk_rules.labels.is_active')"
      />

      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.button.save')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="create"
          @cancel="createDialog = false"
        />
      </template>
    </Dialog>

    <!-- Test dialog -->
    <Dialog
      v-model:visible="testDialog"
      :style="{ width: '450px' }"
      :header="$t('xpbx.settings.trunk_rules.dialogs.test_rule')"
      :modal="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <div class="confirmation-content">
        <!-- Direction -->
        <FormDropdown
          optionLabel="name"
          :options="directionOptions"
          :error="errors?.direction?.[0]"
          v-model:modelValue="form.direction"
          :label="$t('xpbx.settings.trunk_rules.labels.direction')"
          :hint="$t('xpbx.settings.trunk_rules.hints.direction')"
        />

        <!--Calling Prefix -->
        <FormInput
          id="calling_prefix"
          :error="errors?.calling_number?.[0]"
          v-model:modelValue="form.calling_number"
          :label="$t('xpbx.settings.trunk_rules.labels.calling_prefix')"
          :hint="$t('xpbx.settings.trunk_rules.hints.calling_prefix')"
        />

        <!--Called Prefix -->
        <FormInput
          id="called_prefix"
          :error="errors?.called_number?.[0]"
          v-model:modelValue="form.called_number"
          :label="$t('xpbx.settings.trunk_rules.labels.called_prefix')"
          :hint="$t('xpbx.settings.trunk_rules.hints.called_prefix')"
        />

        <!-- Timezone -->
        <FormDropdown
          optionLabel="name"
          :options="timezonesData"
          :error="errors?.timeslot_id?.[0]"
          v-model:modelValue="form.timeslot_id"
          :placeholder="$t('xpbx.settings.trunk_rules.placeholders.timezone')"
          :label="$t('xpbx.settings.trunk_rules.labels.timeslot')"
          :hint="$t('xpbx.settings.trunk_rules.hints.timeslot')"
        />
      </div>
      <template #footer>
        <DialogButtons
          :saveButtonText="$t('xpbx.settings.queue-vnumbers.buttons.submit')"
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="testRule"
          @cancel="testDialog = false"
        />
      </template>
    </Dialog>

    <!-- Test results Dialog -->
    <Dialog
      v-model:visible="testResultDialog"
      :style="{ width: '450px' }"
      :header="$t('xpbx.settings.trunk_rules.dialogs.test_result_rule')"
      :modal="true"
      class="p-fluid relative custom-dialog-heading"
    >
      <!-- Tests results -->
      <div v-if="testValue">
        <p v-if="testValue?.calling_number">
          {{ $t("xpbx.settings.trunk_rules.tests.calling_number") }}
          {{ testValue?.calling_number }}
        </p>
        <p v-if="testValue?.called_number">
          {{ $t("xpbx.settings.trunk_rules.tests.called_number") }}
          {{ testValue?.called_number }}
        </p>
        <p v-if="testValue?.presentation_number">
          {{ $t("xpbx.settings.trunk_rules.tests.presentation_number") }}
          {{ testValue?.presentation_number }}
        </p>
      </div>
      <template #footer>
        <DialogButtons
          :cancelButtonText="$t('xpbx.button.cancel')"
          @save="() => {}"
          @cancel="testResultDialog = false"
        />
      </template>
    </Dialog>
  </section>
</template>

<script>
import { ref, onMounted, watch, computed, inject } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import validator from "@/composables/auth/validator";
import useCarriers from "@/modules/xpbx/composables/useCarriers";
import useTimezones from "@/modules/xpbx/composables/useTimezones";
import useQueueOptions from "@/modules/xpbx/composables/useQueueOptions";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import DialogButtons from "@/modules/xpbx/components/dialogs/DialogButtons.vue";
import FormInput from "@/modules/xpbx/components/forms/FormInput.vue";
import NumberInput from "@/modules/xpbx/components/forms/NumberInput.vue";
import InputSwitch from "@/modules/xpbx/components/forms/InputSwitch.vue";
import FormDropdown from "@/modules/xpbx/components/forms/FormDropdown.vue";
import {
  validateCreateTrunkRule,
  validateCreateTestRule,
} from "@/composables/auth/carrierValidations";
import BaseBreadcrumb from "@/modules/xpbx/components/breadcrumb/Breadcrumb.vue";
import TableActions from "@/modules/xpbx/pages/settings/components/TableActions/TableActions.vue";

export default {
  name: "TrunkRules",

  components: {
    DataTable,
    Column,
    Button,
    Dialog,
    PageHeader,
    InputSwitch,
    FormInput,
    NumberInput,
    FormDropdown,
    DialogButtons,
    TableActions,
    BaseBreadcrumb,
  },

  setup() {
    const t = inject("t");
    const route = useRoute();
    const isEdit = ref(false);
    const submitted = ref(false);
    const createDialog = ref(false);
    const deleteDialog = ref(false);
    const testDialog = ref(false);
    const testResultDialog = ref(false);
    const selectedRecord = ref(null);
    const selectedRecords = ref([]);
    const form = ref({});
    const record = ref({});
    const { errors, clearErrors } = validator();
    const scrollHeight = ref(window.innerHeight - 290);
    const selectText = t("xpbx.settings.dropdown.select_option");

    const {
      rules,
      testValue,
      testRuleRequest,
      findCarrierTrunkRules,
      createCarrierTrunkRule,
      updateCarrierTrunkRule,
      deleteCarrierTrunkRule,
    } = useCarriers();

    const { findTimezones, timezones } = useTimezones();
    const {
      timeoutSelect,
      priorityOptions,
      commandOptions,
      pointOptions,
      getOptionValue,
    } = useQueueOptions();

    const breadcrumbData = computed(() => {
      return [
        {
          label: t("xpbx.settings.trunk_rules.breadcrumb.carriers"),
          route: `/xpbx/settings/carriers`,
        },
        {
          label: t("xpbx.settings.trunk_rules.breadcrumb.carrier_trunks"),
          route: `/xpbx/settings/carrier/${route?.params?.id}?tab=trunk`,
        },
        {
          label: t("xpbx.settings.trunk_rules.breadcrumb.trunk_rules"),
        },
      ];
    });

    const timezonesData = computed(() => {
      if (!timezones.value) return [];

      return timezones.value.map((item) => {
        return {
          name: item.description,
          value: item.id,
        };
      });
    });

    const fillDefaultRecord = () => {
      record.value = {
        point_start: 1,
        point_end: 1,
        calling_prefix: "",
        called_prefix: "",
        command_arg: "",
        priority: 1,
        timeslot_id: "",
        direction: {
          name: selectText,
          value: null,
        },
        command: {
          name: selectText,
          value: null,
        },
        is_active: true,
      };
    };

    // Data table functions
    const openNew = () => {
      fillDefaultRecord();
      isEdit.value = false;
      createDialog.value = true;
    };

    const openTest = () => {
      clearErrors();
      form.value = {
        calling_number: "",
        called_number: "",
        timeslot_id: {
          name: selectText,
          value: null,
        },
        direction: {
          name: selectText,
          value: null,
        },
      };
      testDialog.value = true;
    };

    const closeDialog = (value) => {
      createDialog.value = value;
    };

    const reset = () => {
      fillDefaultRecord();
      clearErrors();
      submitted.value = false;
      selectedRecord.value = null;
    };

    const confirmDeleteSelected = () => {
      deleteDialog.value = true;
    };

    const deleteSelectedRecords = async () => {
      const id = route.params.trunkId;

      if (selectedRecord.value.id)
        await deleteCarrierTrunkRule(id, selectedRecord.value.id);
      deleteDialog.value = false;
    };

    const activeHandle = async (data) => {
      const id = route.params.trunkId;

      const formData = {
        ...data,
        direction: data?.direction?.value || data?.direction || "",
        is_active: data.is_active === 0 ? 1 : 0,
      };

      await updateCarrierTrunkRule(formData, id, formData.id);
    };

    const testRule = async () => {
      const isValid = validateCreateTestRule(form.value);

      if (!isValid) return;

      const id = route.params.trunkId;

      const formData = {
        ...form.value,
        direction:
          selectedRecord.value?.direction?.value ||
          selectedRecord.value?.direction ||
          "",
      };

      const response = await testRuleRequest(formData, id);

      if (response) {
        testDialog.value = false;
        testResultDialog.value = true;
      }
    };

    const editHandle = (data) => {
      isEdit.value = true;

      const direction = trunktypeOptions.find(
        (option) => option.value === data.direction
      );

      const timezone = timezonesData.value.find(
        (option) => option.value === data.timeslot_id
      );

      const command = commandOptions.find(
        (option) => option.value === data.command
      );

      const form = {
        ...data,
        point_start: data.point_start === 0 ? `0` : data.point_start,
        point_end: data.point_end === 0 ? `0` : data.point_end,
        direction: direction || trunktypeOptions[0],
        is_active: data.is_active === 1 ? true : false,
        command: command || commandOptions[0],
        timeslot_id: timezone || timezonesData.value[0],
      };

      record.value = form;
      createDialog.value = true;
    };

    const deleteSelectedRecord = (data) => {
      selectedRecord.value = data;
      confirmDeleteSelected();
    };

    const updateRecord = async (formData) => {
      const id = route.params.trunkId;
      if (formData.id) await updateCarrierTrunkRule(formData, id, formData.id);
      createDialog.value = false;
    };

    const createRecord = async (formData) => {
      const id = route.params.trunkId;
      await createCarrierTrunkRule(formData, id);
      createDialog.value = false;
    };

    const getCommandName = (command) => {
      if (!command) return "";
      return commandOptions.find((option) => option.value === command)?.name;
    };

    const create = async () => {
      submitted.value = true;
      const isValid = validateCreateTrunkRule(record.value);

      const formData = {
        ...record.value,
        direction: getOptionValue(record.value.direction),
        timeslot_id: getOptionValue(record.value.timeslot_id),
        command: getOptionValue(record.value.command),
        is_active: record.value.is_active ? 1 : 0,
      };

      if (!isValid) return;

      if (isEdit.value) {
        formData.id = record.value.id;
        await updateRecord(formData);
      } else {
        await createRecord(formData);
      }
    };

    const trunktypeOptions = [
      {
        name: t(`xpbx.settings.carrier_trunks.options.incoming`),
        value: "INC",
      },
      {
        name: t(`xpbx.settings.carrier_trunks.options.outgoing`),
        value: "OUT",
      },
      { name: t(`xpbx.settings.carrier_trunks.options.blended`), value: "BLE" },
    ];

    const directionOptions = [
      {
        name: t(`xpbx.settings.carrier_trunks.options.incoming`),
        value: "INC",
      },
      {
        name: t(`xpbx.settings.carrier_trunks.options.outgoing`),
        value: "OUT",
      },
    ];

    watch(createDialog, (value) => {
      if (!value) reset();
    });

    onMounted(async () => {
      await findTimezones();

      await findCarrierTrunkRules(route.params.trunkId);
    });

    return {
      isEdit,
      rules,
      timezones,
      submitted,
      form,
      record,
      errors,
      testValue,
      commandOptions,
      timezonesData,
      breadcrumbData,
      clearErrors,
      scrollHeight,
      createDialog,
      deleteDialog,
      testDialog,
      testResultDialog,
      selectedRecord,
      timeoutSelect,
      pointOptions,
      selectedRecords,
      priorityOptions,
      trunktypeOptions,
      directionOptions,
      // Methods
      create,
      openNew,
      openTest,
      testRule,
      editHandle,
      closeDialog,
      activeHandle,
      getCommandName,
      testRuleRequest,
      deleteSelectedRecord,
      confirmDeleteSelected,
      deleteSelectedRecords,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/data-table.scss";
.color-base-green {
  background-color: #32bcad;
}

.color-base-red {
  background-color: #f05a94;
}
</style>