import { computed, unref } from "vue";
import validator from "@/composables/auth/validator";

const hasErrors = computed(() => Object.entries(errors.value).length);
const { checkRequired, errors, clearErrors, checkRequiredOption } = validator();

export function validateCreateTrunk(data) {
  clearErrors();

  data = unref(data);

  let priority = data;
  let service = data;
  let trunktype = data;

  if (data.service?.name) service = data.service;
  if (data.priority?.name) priority = data.priority;
  if (data.trunktype?.name) trunktype = data.trunktype;

  checkRequired("name", data);
  checkRequired("host", data);
  checkRequired("channels", data);
  checkRequired("port", data);
  checkRequired("codecs", data);
  checkRequired("service", service);
  checkRequired("priority", priority);
  checkRequired("trunktype", trunktype);

  return hasErrors.value ? false : true;
}

export function validateCreateTrunkRule(data) {
  clearErrors();

  data = unref(data);

  checkRequired("point_start", data);
  checkRequired("point_end", data);
  checkRequired("calling_prefix", data);
  checkRequired("called_prefix", data);
  checkRequired("command_arg", data);
  checkRequired("priority", data);
  checkRequiredOption("command", data);
  checkRequiredOption("direction", data);
  checkRequiredOption("timeslot_id", data);

  return hasErrors.value ? false : true;
}

export function validateCreateTestRule(data) {
  clearErrors();

  data = unref(data);

  checkRequired("calling_number", data);
  checkRequired("called_number", data);
  checkRequiredOption("direction", data);
  checkRequiredOption("timeslot_id", data);

  return hasErrors.value ? false : true;
}

export function validateCarrierRate(data) {
  clearErrors();

  data = unref(data);

  checkRequired("cli", data);
  checkRequired("prefix", data);
  checkRequired("carrier_rate", data);
  checkRequired("billing_model", data);
  checkRequired("effective_date", data);
  checkRequired("carrier_setup_rate", data);

  return hasErrors.value ? false : true;
}
