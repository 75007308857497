<template>
  <div class="field flex flex-col justify-start">
    <label class="text-left" for="id">{{ label }}</label>
    <div class="container">
      <InputNumber
        v-model="numberValue"
        :useGrouping="false"
        @input="inputChange"
        :min="min"
        :max="max"
        v-if="!isDecimal"
      />
      <InputNumber
        v-else
        v-model="numberValue"
        mode="decimal"
        @input="inputChange"
        :minFractionDigits="1"
        :maxFractionDigits="4"
      />
    </div>
    <small class="p-error" v-if="!!error">{{ error }}</small>
  </div>
  <QueueHint :title="hint" v-if="hint" />
  <div class="mb-4" v-else></div>
</template>

<script>
import { ref } from "vue";
import InputNumber from "primevue/inputnumber";
import QueueHint from "@/modules/xpbx/pages/settings/queue-detail/components/QueueHint/QueueHint.vue";

export default {
  name: "NumberInput",

  components: {
    QueueHint,
    InputNumber,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    modelValue: {
      required: true,
      type: [String, Object, Number, null],
    },

    label: {
      type: String,
      default: "",
    },

    error: {
      type: String,
      default: "",
    },

    hint: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "text",
    },

    max: {
      type: Number,
      default: 100,
    },

    min: {
      type: Number,
      default: 0,
    },

    isDecimal: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const numberValue = ref(props.modelValue);

    const inputChange = (e) => {
      console.log(e?.value);

      emit("update:modelValue", e?.value);
    };

    return {
      numberValue,
      inputChange,
    };
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 200px;
}
</style>